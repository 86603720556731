<template>
  <BCard>
    <h4 class="font-bold text-black text-2xl">
      {{ title }}
    </h4>
    <div class="flex justify-end mt-2">
      <DateRangePicker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'center'"
        class="withdrawal-date-range"
      >
        <template v-slot:input="picker">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="mr-1">
              <span
                v-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)"
                style="color: #828282 !important"
              >
                Hari ini
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last2)"
                style="color: #828282 !important"
              >
                2 Hari Terakhir
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)"
                style="color: #828282 !important"
              >
                7 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(firstDateOfMonth) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >
                Bulan ini
              </span>
              <span
                v-else
                style="color: #828282 !important"
              > Semua </span>
            </div>
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
              alt="Komerce"
            >
          </div>
        </template>
      </DateRangePicker>
      <BFormSelect
        v-model="statusFilter"
        :options="statusOptions"
        placeholder="Pilih Status"
        class="ml-1 mr-1"
        style="width: 20%"
      />
      <BFormInput
        v-model="search"
        placeholder="Cari Nama atau Email"
        style="width: 22%"
        @input="searchData"
      />
    </div>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
        hover
        selectable
        select-mode="single"
        @row-selected="handleToDetail"
      >
        <template #cell(date)="data">
          <span v-if="data.item.date">
            <div>{{ DDMMYYYY(data.item.date) }}</div>
            <div class="mt-1 text-[#828282] text-sm">{{ TIME(data.item.date) }}</div>
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(bank_owner)="data">
          <div class="font-semibold">
            {{ data.item.bank_owner }}
          </div>
          <div class="mt-1 text-[#828282] text-sm">
            {{ data.item.email }}
          </div>
        </template>
        <template #cell(type)="data">
          <div
            v-if="data.item.type === 'balance'"
            class="text-[#08A0F7] p-[5px] bg-[#DFF3FF] border-[1px] border-[#08A0F7] rounded-lg text-center"
          >
            Kompay
          </div>
          <div
            v-if="data.item.type === 'commission'"
            class="text-[#34A770] p-[5px] bg-[#DCF3EB] border-[1px] border-[#34A770] rounded-lg text-center"
          >
            Komisi
          </div>
        </template>
        <template #cell(bank_code)="data">
          <span v-if="data.item.bank_code">{{ data.item.bank_code }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(bank_number)="data">
          <div v-if="data.item.bank_number !== null">
            <div>{{ data.item.bank_number }}</div>
            <div class="mt-1 text-[#828282] text-sm">
              {{ data.item.bank_owner }}
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(status)="data">
          <span
            :class="classStatus(data.item.status)"
            style="border-radius: 20px; padding: 0 10px; min-width: 100px !important"
          >
            {{ lableStatus(data.item.status) }}
          </span>
        </template>
      </BTable>
      <BRow>
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="bg-light d-flex justify-content-center align-items-center p-50 rounded">
            <span class="text-black mr-50"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
              @click="setPerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>

          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="totalPerPage"
            first-number
            last-number
            class="pagination-primary"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </BPagination>
        </BCol>
      </BRow>
    </BOverlay>
  </BCard>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth,
  last150,
  last2,
  last7,
  lastDateOfMonth,
  today,
} from '@/store/helpers'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  LABELDAY, LABELMONTH, YEAR_MONTH_DAY, TIME, DAY_MONTH_YEAR, DDMMYYYY,
} from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { collaboratorAxiosIns } from '@/libs/axios'
import { listWithdrawal, statusOptionsListWithdrawal } from './config'

export default {
  components: { DateRangePicker },
  data() {
    return {
      title: 'Antrian Penarikan',
      isLoading: true,
      search: secureLocalStorage.get('searchWithdrawal') ? secureLocalStorage.get('searchWithdrawal') : '',
      items: [],
      fields: listWithdrawal,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [25, 50, 75, 100, 200],
      totalPerPage: 25,
      statusFilter: null,
      statusOptions: statusOptionsListWithdrawal,
      dateRange: {
        startDate: firstDateOfMonth,
        endDate: today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari ini': [today, today],
        '2 Hari terakhir': [last2, today],
        '7 Hari Terakhir': [last7, today],
        'Bulan ini': [firstDateOfMonth, today],
        'Semua ': [last150, today],
      },
      today,
      last2,
      last7,
      last150,
      lastDateOfMonth,
      firstDateOfMonth,
      YEAR_MONTH_DAY,
      IDR,
      TIME,
      DAY_MONTH_YEAR,
      DDMMYYYY,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getListWhitdrawal()
      },
    },
    statusFilter: {
      handler() {
        this.getListWhitdrawal()
      },
    },
    dateRange: {
      handler() {
        this.getListWhitdrawal()
      },
    },
  },
  mounted() {
    this.getListWhitdrawal()
  },
  methods: {
    async getListWhitdrawal() {
      this.isLoading = true
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
        status: this.statusFilter,
        page: this.currentPage,
        per_page: this.totalPerPage,
        search: this.search,
      }
      const url = '/user/api/v1/admin/withdrawals'
      await collaboratorAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.items = data.results
          this.totalRows = data.pagination.total
          this.isLoading = false
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal load data', timeout: 1000 })
          this.loading = false
        })
    },

    setPerPage(page) {
      this.totalPerPage = page
      this.getListWhitdrawal()
    },
    searchData: _.debounce(function search(text) {
      secureLocalStorage.set('searchWithdrawal', text)
      this.getListWhitdrawal()
    }, 1000),
    lableStatus(value) {
      if (value === 'request') return 'Request Penarikan'
      if (value === 'review') return 'Sedang Direview'
      if (value === 'approved') return 'Disetujui'
      if (value === 'rejected') return 'Ditolak'
      return ''
    },
    classStatus(value) {
      if (value === 'request') return 'requestedList'
      if (value === 'review') return 'on_reviewList'
      if (value === 'approved') return 'completedList'
      if (value === 'rejected') return 'rejectedList'
      return ''
    },
    handleToDetail(value) {
      const { id, user_id } = value[0]
      this.$router.push({ name: 'withdrawal-collaborator-detail', params: { id, user_id } })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../affiliate/Withdrawal.scss';
</style>
